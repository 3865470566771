import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ME100010, ME200005 } from "src/app/common/const-message-id";
import { NumberKeypadComponent } from "src/app/partsCommon/number-keypad/number-keypad.component";
import { RspSpmt10341GetDetailDto } from "src/app/response/rsp-spmt10341-get-detail";
import { CommonService } from "src/app/service/common.service";
import { MessageService } from "src/app/service/message.service";
const ERROR_PRODUCT_STAUTS = "9";
const STORE_ORDER_REQUEST_STATUS = "0";
const HEADQUATER_ORDER_INPUT = "01";
@Component({
  selector: "app-spmt10341-detail-dialog",
  templateUrl: "./spmt10341-detail-dialog.component.html",
  styleUrls: ["./spmt10341-detail-dialog.component.css"],
})
export class Spmt10341DetailDialogComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild(NumberKeypadComponent, { static: true }) numberKeypadComponent: NumberKeypadComponent;
  @ViewChild("orderRequestQty", { static: true }) orderRequestQty: ElementRef;
  requiredMessage: string;
  rangeMessage: string;
  disabledControl: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<Spmt10341DetailDialogComponent>,
    public commonService: CommonService,
    private formBuilder: FormBuilder,
    message: MessageService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: RspSpmt10341GetDetailDto,
  ) {
    this.rangeMessage = message.message[ME200005].replace("%1", "申請数").replace("%2", "1").replace("%3", "99999");
    this.requiredMessage = message.message[ME100010];
  }

  ngAfterViewInit(): void {
    if (!this.disabledControl) {
      this.set10KeyPadTarget("orderRequestQty", this.formGroup.get("orderRequestQty"));
    }
    setTimeout(() => {
      this.orderRequestQty.nativeElement.focus();
    }, 300);
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  result: RspSpmt10341GetDetailDto = {
    orderRequestId: "",
    productCd: "",
    productName: "",
    standardName: "",
    storeApprovalStatusNm: "",
    urgentOrderFlg: "",
    urgentOrderNm: "",
    orderKbn: "",
    orderKbnNm: "",
    orderQty: 0,
    stockQty: 0,
    orderRequestQty: 1,
    requestMsg: "",
    storeComment: "",
    afterOneWeekCount: 0,
    afterTwoWeekCount: 0,
    afterThreeWeekCount: 0,
    afterFourWeekCount: 0,
    storeApprovalStatus: "",
    productStatus: "",
    updDateTime: "",
  };

  formGroup: FormGroup = this.formBuilder.group({
    orderRequestQty: [this.result.orderRequestQty, [Validators.required, Validators.min(1), Validators.max(99999)]],
    storeComment: [this.result.storeComment],
  });

  fnIsRowDisabled(row: RspSpmt10341GetDetailDto) {
    return (
      row.storeApprovalStatus !== STORE_ORDER_REQUEST_STATUS ||
      row.orderKbn === HEADQUATER_ORDER_INPUT ||
      row.productStatus === ERROR_PRODUCT_STAUTS
    );
  }

  ngOnInit(): void {
    this.result = {
      ...this.result,
      ...this.data,
      orderRequestQty: this.data.orderRequestQty ?? this.result.orderRequestQty,
      storeComment: this.data.storeComment ?? this.result.storeComment,
    };

    this.disabledControl = this.fnIsRowDisabled(this.result);
    this.formGroup.patchValue({
      orderRequestQty: this.result.orderRequestQty,
      storeComment: this.result.storeComment,
    });
  }

  btnReflectClick() {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        ...this.result,
        storeComment: this.formGroup.get("storeComment")?.value,
        orderRequestQty: this.formGroup.get("orderRequestQty")?.value,
      });
    }
  }

  btnBackClick() {
    this.dialogRef.close();
  }

  use10KeyPad() {
    return this.commonService.config.use10KeyPad;
  }

  isKeyboardLocked() {
    if (!this.use10KeyPad()) return null;
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  is10KeyPadTarget(id: string) {
    if (id === this.keypadTargetId) return true;
    return false;
  }

  keypadTargetId: string;

  set10KeyPadTarget(id: string, form: AbstractControl) {
    if (!this.commonService.config.use10KeyPad) return;
    let formControl: FormControl = form as FormControl;
    this.keypadTargetId = id;
    this.numberKeypadComponent.setTargetForm(formControl);
  }
}
