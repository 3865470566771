import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Spmt10321Dto } from 'src/app/response/rsp-spmt10321-search';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { Spmt10321Component } from 'src/app/page/spmt10321/spmt10321.component';
import { ReqSpmt10321Confirm } from 'src/app/request/req-spmt10321-confirm';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ReqAccess } from 'src/app/request/req-access';
import { Req } from 'src/app/request/req';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { ErrorNotificationDialogComponent } from '../error-notification-dialog/error-notification-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { Rsp } from 'src/app/response/rsp';
import { MI200002, MQ200003 } from 'src/app/common/const-message-id';
import { MessageService } from 'src/app/service/message.service';

class DTAConfirmDialogConstant{
  public static readonly MESSAGESEARCHING: string = '検索中・・・';
  public static readonly EMPTY_STRING = '';
}

interface ITAConfirmDialogTableColumnDef extends TableColumnDef{
  isInputText : boolean;
}

class ItemSelect{
  slipNo : string;
  statusNm : string;
  totalDtQty : string;
  totalOrderPriceExc : string;
  slipCreDate : string;
  requesterNm : string;
  approverNm : string;
  slipComment : string;
  approverComment : FormControl;
  checkStatusFlg : string;
  reqStatus : string;
  updDateTime : string;
  delFlg: string;
  redBlackFlg: string;
  storeCd: string;
  storeNm: string;
}

@Component({
  selector: 'spmt10321-confirm-dialog',
  templateUrl: './spmt10321-confirm-dialog.component.html',
  styleUrls: ['./spmt10321-confirm-dialog.component.css']
})
export class Spmt10321ConfirmDialogComponent implements OnInit, AfterViewInit {

  public columnIdDialog: string[] = ["slipNo","statusNm","totalDtQty","totalOrderPriceExc","slipCreDate","requesterNm","approverNm","slipComment","approverComment"]

  public columnDefsItems: ITAConfirmDialogTableColumnDef[] = [
    {columnId: "slipNo", header: "伝票番号", width: 80, align: "left",rowspan: 1, colspan: 3,isInputText : false},
    {columnId: "statusNm", header: "申請状態", width: 60, align: "left",rowspan: 1, colspan: 1,isInputText : false},
    {columnId: "totalDtQty", header: "数量合計", width: 70, align: "right",rowspan: 1, colspan: 1,isInputText : false, numberPipe: '1.0-0'},
    {columnId: "totalOrderPriceExc", header: "原価金額合計", width: 90, align: "right",rowspan: 1, colspan: 1,isInputText : false, numberPipe: '1.2-2'},
    {columnId: "slipCreDate", header: "伝票作成日", width: 80, align: "center",rowspan: 1, colspan: 1,isInputText : false},
    {columnId: "requesterNm", header: "申請者", width: 100, align: "left",rowspan: 1, colspan: 1,isInputText : false},
    {columnId: "approverNm", header: "承認者", width: 100, align: "left",rowspan: 1, colspan: 1,isInputText : false},
    {columnId: "slipComment", header: "申請者コメント", width: 150, align: "left",rowspan: 1, colspan: 1,isInputText : false},
    {columnId: "approverComment", header: "承認者コメント", width: 150, align: "left",rowspan: 1, colspan: 1,isInputText : true},
  ]

  access: ReqAccess | any = {
    ...this.commonService.loginUser,
    cmpnyCd: DTAConfirmDialogConstant.EMPTY_STRING,
    lang: DTAConfirmDialogConstant.EMPTY_STRING,
    storeCd: DTAConfirmDialogConstant.EMPTY_STRING,
  };


  public itemTableWidth: any;
  public itemsShow: ItemSelect[] = [];
  public items: ItemSelect[] = [];
  public itemRecordCount: number = 0;
  private subcriptionYesNoDialog: Subscription;
  public slipNo: string;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10321Component>,
    public httpBasic: HttpBasicService,
    @Inject(MAT_DIALOG_DATA) public data: {row: Spmt10321Dto[], statusConfirm : String},
    private changeDetectorRef: ChangeDetectorRef,
    private message: MessageService,
    public commonService: CommonService) { }
    public statusConfirm : String = this.data.statusConfirm;

  ngOnInit() {
    this.fnGetAccessInfo();
    this.calcTableWidth();
  }

  ngAfterViewInit() {
    this.initTable();
    this.changeDetectorRef.detectChanges();
  }

  calcTableWidth() {
    this.itemTableWidth = {"width": "100%"};
  }

  checkResult(response: Rsp) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subcription = dialogRef.afterClosed().subscribe(() => {
        this.dialogRef.close({isClose : true, errorMessageID: response.normalError[0].errId});
        subcription.unsubscribe();
      });
      return;
    }

    const dialogRef = this.commonService.dialog.open(NotificationDialogComponent, {
      data: {title: this.commonService.pageTitle, message: this.message.message[MI200002]}
    });
    const subcription = dialogRef.afterClosed().subscribe(() => {
      this.dialogRef.close({isClose : true});
      subcription.unsubscribe();
    });
  }

  initTable(){
    this.data.row.forEach((item: Spmt10321Dto) => {
      this.items.push({
        ...item,
        approverComment: new FormControl(item.approverComment)
      })
    })
    this.itemRecordCount = this.data.row.length ?? 0;
    this.pageChanged();
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }

  getRequestData (){
    return this.items.map((item: ItemSelect) => ({
      ...item,
      approverComment: item.approverComment.value
    }));
  }

  fnGetAccessInfo() {
    let req: Req = {
      access: this.access
    };
    this.httpBasic.beforeRequestStart();
    this.httpBasic.getUserInfo(req).subscribe({
      next: (res) => {
        if (this.httpBasic.handleAppError(res)) return;
        if (res.rows.length > 0) {
          this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
          this.access.storeCd = res.rows[0].mainStoreCd;
          this.access.sysModeCd = res.rows[0].sysModeCd;
          this.access.lang = res.rows[0].mainLang;
        }
        this.httpBasic.afterRequestComplete();
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      }
    });
  }

  confirmBtn(){
    this.subcriptionYesNoDialog = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200003]).subscribe(data => {
      if(data) this.confirmPrc();
      this.subcriptionYesNoDialog.unsubscribe();
    })
  }

  confirmPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, DTAConfirmDialogConstant.MESSAGESEARCHING);
    const request: ReqSpmt10321Confirm = {
      rows: this.getRequestData(),
      access: this.access,
      statusConfirm: this.statusConfirm
    }
    const subsc = this.httpBasic.confirmSpmt10321(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }
}
