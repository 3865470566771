import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { NotificationDto, ReqNotificationConfirmed, RspNotificationConfirmed } from 'src/app/webservice/notification';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit, OnDestroy {

  public notificationList: NotificationDto[] = [];
  // private timerId: number;

  constructor(
    public commonService: CommonService,
    private httpBasic: HttpBasicService,
    public notificationService: NotificationService,
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    if (this.commonService.config.useNotification) {
      this.notificationService.init(()=>{ this.initComplete(); });
    }
  }

  ngOnDestroy(): void {
  }

  initComplete() {
    this.notificationList = this.notificationService.notificationList;

  }

  isOpen() {
    if (!this.commonService.openNotification) return false;
    if (this.commonService.pageTitle !== "TOP" && this.notificationList.length == 0) return false;
    return true;
  }

  getNotification() {
    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    this.notificationService.getNotification(
      () => {
        this.commonService.closeSpinnerForSubComp(ref);
        this.initComplete();        
      },
      true);
  }

  closeNotification() {
    this.commonService.openNotification = false;
    window.dispatchEvent(new Event('resize'));
  }

  confirmed(notification: NotificationDto) {
    let request: ReqNotificationConfirmed = {
      access: this.commonService.loginUser,
      notificationId: notification.notificationId
    };

    this.commonService.openSpinner(this.commonService.pageTitle, "更新中・・・");
    let subsc = this.httpBasic.generalRequest("NotificationConfirmed", request).subscribe(
      (response: RspNotificationConfirmed) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        if (this.httpBasic.handleAppError(response)) return;
        this.removeNotification(notification);
      },
      (error) => {
        subsc.unsubscribe();
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );
  }

  removeNotification(notification: NotificationDto) {
    for (let i = 0; i < this.notificationList.length; i++) {
      if (this.notificationList[i].notificationId === notification.notificationId) {
        this.notificationList.splice(i, 1);
        break;
      }
    }
  }

  onLinkClick(event: MouseEvent, row: any) {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const href = (target as HTMLAnchorElement).href; 
    if (href) {
      const pageMenuName = (target as HTMLAnchorElement).innerText; 
      const url = new URL(href);
      const screenId = url.pathname.split('/').pop();
      // Access query paramete
      const queryParams = new URLSearchParams(url.search);
  
      const queryObject: Record<string, string | string[]> = {};
      
      // Convert query string to an object
      queryParams.forEach((value, key) => {
        queryObject[key] = value;
      });
      this.commonService.pageMenuName = pageMenuName;
      this.closeNotification()
      this.router.navigate([`/${screenId}`], {state: {...queryObject, storeCd: row.storeCd}});
    }
  }
}
