import { Rsp } from "./rsp";

export enum CategoryLevel {
    GMAIN = 1,
    GMID = 2,
    GSMALL = 3,
    GSUB = 4,
}

export interface UserDto {
  userCd: string;
  userName: string;
}

export interface CategoryDto {
  ctgCd: string;
  ctgName: string;
  ctgLevel?: CategoryLevel;
}

export interface StoreApprovalStatusDto {
  status: string;
  statusName: string;
}

export interface OrderClassificationDto {
  orderKbn: string;
  orderKbnName: string;
}

export interface RspSpmt10341Init extends Rsp {
  storeApprovalStatusList: StoreApprovalStatusDto[];
  middleCategoryList: CategoryDto[];
  smallCategoryList: CategoryDto[];
  userList: UserDto[];
  orderKbnList: OrderClassificationDto[];
}
